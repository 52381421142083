<template>
  <div class="addstaff" @click.stop>
    <h5>社員追加</h5>
    <dl>
      <dt>所属</dt>
      <dd>
        <select v-model="staffData.belong">
          <option value="">--</option>
          <option
            v-for="(item, index) in domains" :key="index"
          >{{ item }}</option>
        </select>
      </dd>
      <dt>社員番号</dt>
      <dd :class="{error: states.no === false}">
        <input type="number" v-model="staffData.no" @focus="unsetError('no')" @blur="validate">
        <p class="error">社員番号を入力してください</p>
      </dd>
      <dt>氏名</dt>
      <dd :class="{error: states.nickname === false}">
        <input type="text" v-model="staffData.nickname" @focus="unsetError('nickname')" @blur="validate">
        <p class="error">氏名を入力してください</p>
      </dd>
      <dt>性別</dt>
      <dd class="sex">
        <label :class="{checked: staffData.sex === '男性'}"><span></span><input type="radio" name="sex" value="男性" v-model="staffData.sex">男性</label>
        <label :class="{checked: staffData.sex === '女性'}"><span></span><input type="radio" name="sex" value="女性" v-model="staffData.sex">女性</label>
      </dd>
      <dt>携帯電話番号</dt>
      <dd :class="{error: states.keitai === false, duplicated: duplicated}">
        <input type="tel" v-model="staffData.keitai" @focus="unsetError('keitai')" @blur="chkDuplicate">
        <p class="error">携帯電話番号を入力してください</p>
        <p class="duplicated">既に登録された携帯電話番号です</p>
      </dd>
      <dt>生年月日</dt>
      <dd><input type="date" v-model="staffData.birth" :max="today" min="1960-01-01"></dd>
      <dt>入社経緯</dt>
      <dd>
        <select v-model="staffData.adoption">
          <option value="">--</option>
          <option>新卒</option>
          <option>未経験（社会人経験有）</option>
          <option>ポテンシャル/キャリア採用</option>
          <option>スカウト採用</option>
          <option>その他</option>
        </select>
      </dd>
      <dt>最終学歴</dt>
      <dd>
        <select v-model="staffData.graduate">
          <option value="">--</option>
          <option
            v-for="(item, index) in initialgrade" :key="index"
          >{{ item.label }}</option>
        </select>
      </dd>
      <dt class="last">経験年数</dt>
      <dd class="last">
          <select v-model="staffData.yoe">
            <option value="">--</option>
            <option
              v-for="num in 25" :key="num"
              :value="num"
            >{{ num }}年</option>
          </select>
      </dd>
    </dl>
    <div class="sleeve">
      <button
        @click="addStaff"
      >追加</button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      domains: this.$store.getters['Client/clientData'].settings.domains,
      initialgrade: this.$store.getters['Client/clientData'].settings.initialgrade,
      staffData: {
        belong: this.$store.getters['Modal/payload']
      },
      states: {
        no: true,
        nickname: true,
        keitai: true
      },
      duplicated: false
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters['Modal/error'],
      (newValue) => {
        this.duplicated = newValue
      }
    )
    if (this.$store.getters['Staff/staffList']) {
      this.staffData.no = this.maxStaffNo(this.$store.getters['Staff/staffList'])
    }
  },
  methods: {
    // 社員番号の最大値＋１
    maxStaffNo (list) {
      let staffno = 1
      Object.keys(list).forEach(dom => {
        Object.keys(list[dom]).forEach(staff => {
          staffno = Math.max(staffno, list[dom][staff].id)
        })
      })
      return staffno + 1
    },
    // スタッフ追加
    addStaff () {
      if (this.validate() && !this.duplicated) {
        this.$store.commit('Staff/staffData', this.staffData)
        this.$store.dispatch('Staff/addStaff')
      }
    },
    validate () {
      let res = true
      Object.keys(this.states).forEach(el => {
        this.states[el] = this.staffData[el] !== '' && this.staffData[el] !== undefined
        res *= this.states[el]
      })
      return res
    },
    chkDuplicate (e) {
      console.log(e.target.value)
      this.$store.dispatch('Modal/chkDuplicate', e.target.value)
    },
    // エラー解除
    unsetError (str) {
      this.states[str] = true
      this.duplicated = false
    }
  },
  computed: {
    nullCheck () {
      let res = true
      Object.keys(this.states).forEach(el => {
        res *= this.states[el]
      })
      return res
    },
    today () {
      const date = new Date()
      return (date.toJSON().split('T'))[0]
    }
  }
}
</script>
