import axios from 'axios'

const state = {
  staffList: {},
  staffData: {},
  avatarImg: '',
  history: []
}

const getters = {
  staffList: (state) => state.staffList,
  staffData: (state) => state.staffData,
  avatarImg: (state) => state.avatarImg,
  history: (state) => state.history
}

const mutations = {
  staffList (state, staffList) {
    state.staffList = staffList
  },
  staffData (state, staffData) {
    state.staffData = staffData
  },
  avatarImg (state, avatarImg) {
    state.avatarImg = avatarImg
  },
  history (state, history) {
    state.history = history
  }
}

const actions = {
  //
  // 社員新規追加
  //
  async addStaff ({ getters, commit, dispatch }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jjs/v2/staff/add', getters.staffData)
        .then((response) => {
          // console.log(response.data)
          // ID,Password
          const staffData = getters.staffData
          staffData.password = response.data.password
          staffData.userid = response.data.userid
          commit('Modal/show', false, { root: true })
          commit('Modal/content', false, { root: true })
          commit('Modal/payload', {}, { root: true })
          dispatch('loadStaffList')
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 所属上司(１次評価者)更新
  //
  updateBoss ({ getters, commit }, data) {
    axios
      .patch('/wp-json/jjs/v2/staff/updateboss', {
        id: data.id,
        boss: data.boss
      })
      .then((response) => {
        // console.log(response.data)
        const staffList = Object.assign({}, getters.staffList)
        Object.keys(staffList).forEach(el => {
          const found = staffList[el].find(staff => parseInt(staff.userid) === parseInt(data.id))
          if (found !== undefined) {
            found.boss = data.boss
          }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  },
  //
  // 所属上司(２次評価者)更新
  //
  updateBigBoss ({ getters, commit }, data) {
    axios
      .patch('/wp-json/jjs/v2/staff/updatebigboss', {
        id: data.id,
        boss: data.boss
      })
      .then((response) => {
        // console.log(response.data)
        const staffList = Object.assign({}, getters.staffList)
        Object.keys(staffList).forEach(el => {
          const found = staffList[el].find(staff => parseInt(staff.userid) === parseInt(data.id))
          if (found !== undefined) {
            found.bigboss = data.boss
          }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  },
  //
  // 所属上司(１次評価者)解除
  //
  unsetBoss ({ getters, commit }, id) {
    axios
      .patch('/wp-json/jjs/v2/staff/unsetboss', id)
      .then((response) => {
        // console.log(response.data)
        const staffList = Object.assign({}, getters.staffList)
        Object.keys(staffList).forEach(el => {
          const found = staffList[el].find(staff => parseInt(staff.userid) === parseInt(id))
          if (found !== undefined) {
            found.boss = ''
          }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  },
  //
  // 所属上司(２次評価者)解除
  //
  unsetBigBoss ({ getters, commit }, id) {
    axios
      .patch('/wp-json/jjs/v2/staff/unsetbigboss', id)
      .then((response) => {
        // console.log(response.data)
        const staffList = Object.assign({}, getters.staffList)
        Object.keys(staffList).forEach(el => {
          const found = staffList[el].find(staff => parseInt(staff.userid) === parseInt(id))
          if (found !== undefined) {
            found.bigboss = ''
          }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  },
  //
  // スタッフ情報更新
  //
  async updateStaffData ({ getters, commit, dispatch }, id) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .patch('/wp-json/jjs/v2/staff/update', {
          id: id,
          data: getters.staffData
        })
        .then((response) => {
          // console.log(response.data)
          dispatch('loadDetail', id)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // Avatar画像更新
  updateAvatar ({ context }, data) {
    const params = new FormData()
    params.append('image', data.image)
    params.append('id', data.id)
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data'
    axios
      .post('/wp-json/jjs/v2/staff/setavatar', params)
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  deleteAvatar ({ context }, id) {
    axios
      .delete('/wp-json/jjs/v2/staff/avatar/delete/' + id)
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  //
  // スタッフ詳細読み込み
  loadDetail ({ getters, commit, dispatch }, id) {
    commit('processing', true, { root: true })
    console.log('load staff detail')
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/staff/' + id)
        .then((response) => {
          // console.log(response.data)
          commit('staffData', response.data)
        })
        .catch((error) => {
          if (error.response.status === 403) {
            dispatch('ShowError', '対象ユーザーの表示権限がありません', { root: true })
          }
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // スタッフリスト読み込み
  async loadStaffList ({ getters, commit }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/staff/list')
        .then((response) => {
          // console.log(response.data)
          commit('staffList', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 賞与評価用スタッフリスト読み込み
  //
  async loadStaffListBonus ({ getters, commit }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/staff/list/bonus')
        .then((response) => {
          console.log(response.data)
          commit('staffList', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 指定スタッフを評価者に指定(トグル)
  //
  setManager ({ getters, commit }, id) {
    axios
      .get('/wp-json/jjs/v2/staff/set/manager/' + id)
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  //
  // 【get】History
  async getHistory ({ commit }, id) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/staff/history/' + id)
        .then((response) => {
          console.log(response.data)
          commit('history', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  }
}

const Staff = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Staff
