<template>
  <div>
    <h1 class="titlelogo"><router-link :to="{ name: 'ManagerDashboard', params: { dom: domain } }">ココミラ<i>人事評価</i></router-link></h1>
    <h2>管理職メニュー</h2>
    <ul>
      <li><router-link :to="{ name: 'ManagerDashboard', params: { dom: domain } }">ダッシュボード</router-link></li>
      <li><router-link :to="{ name: 'StaffDashboard' }">マイページ</router-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      domain: localStorage.getItem('domain')
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters['Auth/domain'],
      (newValue) => {
        this.domain = newValue
      }
    )
  },
  methods: {
  },
  watch: {
  }
}
</script>
