<template>
  <div class="dashboard">
    dashboard view
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  mounted () {
    this.$store.commit('title', 'ダッシュボード')
  },
  methods: {
  },
  computed: {
  },
  watch: {
  }
}
</script>
