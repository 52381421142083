<template>
  <div>
    <ul class="stafflist">
      <NamePlateSmall
        v-for="(item, index) in allStaff" :key="index"
        :name="item.name"
        :avatar="avatarUrl(item)"
        :id="item.userid"
        :belong="item.belong"
        :job="item.jobtitle"
        :class="{ hasboss: item.boss, hasbigboss: item.bigboss }"
      ></NamePlateSmall>
    </ul>
  </div>
</template>

<script>
import NamePlateSmall from '@/components/NamePlateSmall.vue'
export default {
  components: {
    NamePlateSmall
  },
  data () {
    return {
      staffList: [],
      avatar_m: require('@/assets/avatar_male.svg'),
      avatar_f: require('@/assets/avatar_female.svg')
    }
  },
  mounted () {
    this.$store.dispatch('Staff/loadStaffList')
    this.$store.watch(
      (state, getters) => getters['Staff/staffList'],
      (newValue) => {
        this.staffList = newValue
      }
    )
  },
  methods: {
  },
  computed: {
    allStaff () {
      const arr = []
      Object.keys(this.staffList).forEach(el => {
        this.staffList[el].forEach(staff => {
          arr.push(staff)
        })
      })
      return arr
    },
    avatarUrl () {
      return (staff) => {
        let url = staff.avatar
        if (!url) {
          switch (staff.sex) {
            case '男性':
              url = this.avatar_m
              break
            case '女性':
              url = this.avatar_f
              break
            default:
              url = this.avatar_m
          }
        }
        return url
      }
    }
  }
}
</script>

<style scoped>
.draggable {
  padding: 10px;
  border: 2px dashed #aaa;
}
</style>
