<template>
  <div>
    <div class="gradeindicator">
      <label>現在のポイント</label>
      <div>
        <span class="point">
          <em>{{ point }}</em><i>/{{ targetPoint }}</i>
        </span>
      </div>
      <button
        @click="promoteStaff"
        :disabled="!enough"
      ><i class="fa-solid fa-thumbs-up"></i>昇格</button>
    </div>
    <div class="actions">
      <div
        @click="showProf"
        :class="{ current: mode === 'profile' }"
      >
        <div>
          <i class="fa-solid fa-address-card"></i>
          プロフィール
        </div>
      </div>
      <div
        @click="setMode('history')"
        :class="{ current: mode === 'history' }"
      >
        <div>
          <i class="fa-solid fa-chart-line"></i>
          個人履歴
        </div>
      </div>
      <div
        @click="setMode('evalhistory')"
        :class="{ current: mode === 'evalhistory' }"
      >
        <div>
          <i class="fa-solid fa-award"></i>
          評価履歴
        </div>
      </div>
      <div
      @click="setMode('bonushistory')"
        :class="{ current: mode === 'bonushistory' }"
      >
        <div>
          <i class="fa-solid fa-sack-dollar"></i>
          賞与履歴
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      cap: localStorage.getItem('capability'),
      staffname: this.$store.getters['Staff/staffData'].nickname,
      mode: 'profile'
    }
  },
  mounted () {
    // console.log(this.$store.getters['Defs/classDefs'].lvmax)
    this.$store.watch(
      (state, getters) => getters['Auth/capability'],
      (newValue) => {
        this.cap = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters['Staff/staffData'],
      (newValue) => {
        this.staffname = newValue.nickname
        // this.point = newValue.point
      }
    )
    this.$store.watch(
      (state, getters) => getters.mode,
      (newValue) => {
        this.mode = newValue
      }
    )
  },
  methods: {
    promoteStaff () {
      this.$store.dispatch('Eval/promoteStaff')
    },
    showProf () {
      this.$store.commit('mode', 'profile')
    },
    // placeEval () {
    //   this.$store.commit('Eval/id', false)
    //   this.$store.commit('mode', 'periodical')
    // },
    // placeEvalBonus () {
    //   this.$store.commit('Eval/id', false)
    //   this.$store.commit('mode', 'bonus')
    // },
    // showHistory () {
    //   this.$store.commit('mode', 'history')
    // },
    setMode (mode) {
      this.$store.commit('Eval/id', false)
      this.$store.commit('mode', mode)
    }
  },
  computed: {
    point () {
      let point = this.$store.getters['Staff/staffData'].point
      if (!point || point === undefined) {
        point = 0
      }
      return point
    },
    targetPoint () {
      const arr = this.$store.getters['Defs/classDefs'].point
      const cls = this.$store.getters['Staff/staffData'].class
      return arr && cls ? arr[parseInt(cls) - 1] : 0
    },
    enough () {
      return this.point >= this.targetPoint
    }
  }
}
</script>

<style scoped>
@media (min-width: 1700px) {
  .actions > div {
    padding: 15px;
    font-size: 0.9rem;
  }
}
@media (max-width: 1699px) {
  .actions > div {
    padding: 5px;
    font-size: 14px;
  }
}
.grades {
  margin-bottom: 25px;
}
.grades > div {
  border: 2px solid var(--border-color);
  background-color: #fff;
  padding: 15px;
  margin-bottom: 5px;
  position: relative;
  min-height: 80px;
}
.grades label {
  position: absolute;
  top: 0;
  left: 3px;
  font-size: 12px;
  font-weight: bold;
}
.actions {
  margin-bottom: 30px;
}
.actions > div {
  margin-bottom: 5px;
  border: 3px solid var(--theme-color);
  color: var(--theme-color);
  background-color: #fff;
  display: grid;
  align-items: center;
  justify-items: center;
  font-weight: bold;
  text-align: center;
  user-select: none;
}
.actions > div:hover {
  cursor: pointer;
}
.actions > div.current {
  background-color: var(--theme-color);
  color: #fff;
}
.actions i {
  display: block;
  text-align: center;
  font-size: 30px;
}
</style>
