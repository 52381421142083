<template>
  <div v-if="parseInt(askeval) > 0">
    <div v-if="periodicalList" class="manager_evallist">
      <h4>定期評価</h4>
      <ul class="stafflist" v-if="periodicalList.ev1">
        <li v-for="(item, index) in periodicalList.ev1" :key="index" @click="placeEval(item.id)">
          <picture>
            <img :src="item.avatar" :alt="item.name">
          </picture>
          <div>
            {{ item.name }}
            <div class="meta">{{ item.belong }} {{ item.jobtitle }}</div>
          </div>
        </li>
      </ul>
      <ul class="stafflist" v-if="periodicalList.ev2">
        <li v-for="(item, index) in periodicalList.ev2" :key="index" @click="placeEval(item.id)">
          <picture>
            <img :src="item.avatar" :alt="item.name">
          </picture>
          <div>
            {{ item.name }}
            <div class="meta">{{ item.belong }} {{ item.jobtitle }}</div>
          </div>
        </li>
      </ul>
    </div>

    <div v-if="bonusList" class="manager_evallist">
      <h4>賞与評価</h4>
      <ul class="stafflist" v-if="bonusList.ev1">
        <li v-for="(item, index) in bonusList.ev1" :key="index" @click="placeEvalBonus(item.id)">
          <picture>
            <img :src="item.avatar" :alt="item.name">
          </picture>
          <div>
            {{ item.name }}
            <div class="meta">{{ item.belong }} {{ item.jobtitle }}</div>
          </div>
        </li>
      </ul>
      <ul class="stafflist" v-if="bonusList.ev2">
        <li v-for="(item, index) in bonusList.ev2" :key="index" @click="placeEvalBonus(item.id)">
          <picture>
            <img :src="item.avatar" :alt="item.name">
          </picture>
          <div>
            {{ item.name }}
            <div class="meta">{{ item.belong }} {{ item.jobtitle }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      askeval: false,
      periodicalList: this.$store.getters['Manager/needEvalList'].periodical,
      bonusList: this.$store.getters['Manager/needEvalList'].bonus
    }
  },
  mounted () {
    // this.$store.dispatch('Manager/loadNeedEvalList')
    this.$store.watch(
      (state, getters) => getters['Client/clientData'],
      (newValue) => {
        this.askeval = newValue.options.askeval.periodical || newValue.options.askeval.bonus
      }
    )
    this.$store.watch(
      (state, getters) => getters['Manager/needEvalList'],
      (newValue) => {
        // console.log(newValue)
        this.periodicalList = newValue.periodical
        this.bonusList = newValue.bonus
      }
    )
  },
  methods: {
    placeEval (id) {
      // this.$store.dispatch('Defs/loadEvalDefs')
      // this.$store.dispatch('Staff/loadDetail', id)
      // this.$store.commit('currentStaffId', id)
      this.$store.commit('Staff/staffData', {})
      this.$router.push({ name: 'EvalTeiki', params: { dom: this.$route.params.dom, id: id } })
    },
    placeEvalBonus (id) {
      this.$store.commit('Staff/staffData', {})
      this.$router.push({ name: 'EvalBonus', params: { dom: this.$route.params.dom, id: id } })
    }
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style scoped>
.stafflist li:hover {
  cursor: pointer;
}
</style>
