<template>
  <div>
    <h1 class="titlelogo"><router-link :to="{ name: 'StaffDashboard'}">ココミラ<i>人事評価</i></router-link></h1>
    <h2>スタッフメニュー</h2>
    <ul>
      <li><router-link :to="{ name: 'StaffDashboard' }">ダッシュボード</router-link></li>
      <li><router-link :to="{ name: 'SelfEvalLog' }">自己評価履歴</router-link></li>
      <li><router-link :to="{ name: 'BonusLog' }">賞与履歴</router-link></li>
    </ul>
  </div>
</template>
