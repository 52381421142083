<template>
  <div>
    <ul v-if="domain">
      <li><router-link :to="{ name: 'Client', params: { dom: domain } }">企業情報</router-link></li>
      <li><router-link :to="{ name: 'DomainDef', params: { dom: domain } }">部門定義</router-link></li>
      <li><router-link :to="{ name: 'RoleDef', params: { dom: domain } }">役割定義</router-link></li>
      <li><router-link :to="{ name: 'GradeDef', params: { dom: domain } }">昇格・昇級</router-link></li>
      <li><router-link :to="{ name: 'SalaryDef', params: { dom: domain } }">給与テーブル</router-link></li>
      <li><router-link :to="{ name: 'InitialDef', params: { dom: domain } }">初任給</router-link></li>
      <li><router-link :to="{ name: 'CareerDef', params: { dom: domain } }">キャリア採用</router-link></li>
      <li><router-link :to="{ name: 'AllowanceDef', params: { dom: domain } }">手当</router-link></li>
      <li><router-link :to="{ name: 'BonusDef', params: { dom: domain } }">賞与</router-link></li>
      <li><router-link :to="{ name: 'EvalDef', params: { dom: domain } }">評価項目【年次評価】</router-link></li>
      <li><router-link :to="{ name: 'EvalBonusDef', params: { dom: domain } }">評価項目【賞与】</router-link></li>
    </ul>
    <div v-if="cap === 'owner'" class="informbox">
      <label>社員全員に通知</label>
    </div>
    <div v-if="cap === 'superadmin'" class="informbox">
      <label>{{ companyname }}に通知</label>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      domain: localStorage.getItem('domain'),
      cap: localStorage.getItem('capability'),
      companyname: ''
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters['Auth/domain'],
      (newValue) => {
        this.domain = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters['Auth/capability'],
      (newValue) => {
        this.cap = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters['Client/clientData'],
      (newValue) => {
        this.companyname = newValue.title
      }
    )
  },
  methods: {
  },
  watch: {
  }
}
</script>
