<template>
  <div>
    <AdminOps v-if="current === 'AdminDashboard'"></AdminOps>
    <ClientRootOps v-else-if="ClientRoot.includes(current)"></ClientRootOps>
    <ClientAddOps v-else-if="current === 'AddClient'"></ClientAddOps>
    <StaffListOps v-else-if="current === 'StaffList'"></StaffListOps>
    <StaffAddOps v-else-if="current === 'StaffAdd'"></StaffAddOps>
    <StaffDetailOps v-else-if="current === 'StaffDetail' || current === 'EvalPeriodical'"></StaffDetailOps>
    <StaffGroupOps v-else-if="current === 'EvalGroup'"></StaffGroupOps>
    <StaffOps v-else-if="current === 'StaffDashboard'"></StaffOps>
    <ManagerDashboardOps v-else-if="current === 'ManagerDashboard'"></ManagerDashboardOps>
  </div>
</template>

<script>
import AdminOps from '@/components/RightSideMenu/AdminOps.vue'
import ClientRootOps from '@/components/RightSideMenu/ClientRootOps.vue'
import ClientAddOps from '@/components/RightSideMenu/ClientAddOps.vue'
import StaffListOps from '@/components/RightSideMenu/StaffListOps.vue'
import StaffAddOps from '@/components/RightSideMenu/StaffAddOps.vue'
import StaffDetailOps from '@/components/RightSideMenu/StaffDetailOps.vue'
import StaffGroupOps from '@/components/RightSideMenu/StaffGroupOps.vue'
import StaffOps from '@/components/RightSideMenu/StaffOps.vue'
import ManagerDashboardOps from '@/components/RightSideMenu/ManagerDashboardOps.vue'
export default {
  components: {
    AdminOps,
    ClientRootOps,
    ClientAddOps,
    StaffListOps,
    StaffAddOps,
    StaffDetailOps,
    StaffGroupOps,
    StaffOps,
    ManagerDashboardOps
  },
  data () {
    return {
      current: this.$route.name,
      ClientRoot: ['Client', 'DomainDef', 'RoleDef', 'GradeDef', 'InitialDef', 'SalaryDef', 'CareerDef', 'AllowanceDef', 'EvalDef', 'EvalBonusDef', 'BonusDef'],
      cap: this.$store.getters['Auth/capability']
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters['Auth/capability'],
      (newValue) => {
        this.cap = newValue
      }
    )
  },
  methods: {
  },
  watch: {
    $route: {
      handler (newValue) {
        this.current = newValue.name
      },
      deep: true
    }
  }
}
</script>
