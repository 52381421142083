import axios from 'axios'
import router from '@/router'

const state = {
  cat: '',
  qanda: [
    {
      question: '1.今期、貢献できたこと、また改善できたこと、新たに取り組んだ仕事など',
      answer: ''
    },
    {
      question: '2.今期改善が必要な事項、結果が出せなかったこと等',
      answer: ''
    },
    {
      question: '3.対象期間において自分の仕事を下記の項目ごとに５段階で評価すると',
      answer: []
    },
    {
      question: '4.今後の課題、目標',
      answer: ''
    },
    {
      question: '5.その他仕事での気づき、意見',
      answer: ''
    }
  ],
  qandaBonus: [
    {
      question: '1.ここまでの貢献できたこと、また改善できたこと、新たに取り組んだ仕事など',
      answer: ''
    },
    {
      question: '2.ここまでで改善が必要な事項、結果が出せなかったこと等',
      answer: ''
    },
    {
      question: '3.今後の課題、目標',
      answer: []
    },
    {
      question: '4.その他仕事での気づき、意見',
      answer: ''
    }
  ],
  hexagonalmap: [],
  list: [],
  date: '',
  range: [],
  meta: []
}

const getters = {
  cat: (state) => state.cat,
  qanda: (state) => state.qanda,
  hexagonalmap: (state) => state.hexagonalmap,
  list: (state) => state.list,
  date: (state) => state.date,
  range: (state) => state.range,
  meta: (state) => state.meta,
  done: (state) => state.done
}

const mutations = {
  cat (state, cat) {
    state.cat = cat
  },
  qanda (state, qanda) {
    state.qanda = qanda
  },
  hexagonalmap (state, hexagonalmap) {
    state.hexagonalmap = hexagonalmap
    state.qanda[2].answer = hexagonalmap
  },
  list (state, list) {
    state.list = list
  },
  date (state, date) {
    state.date = date
  },
  range (state, range) {
    state.range = range
  },
  meta (state, meta) {
    state.meta = meta
  }
}

const actions = {
  //
  // 提出済みチェック
  chkIfDone ({ commit }) {
    axios
      .get('/wp-json/jjs/v2/selfcheck/chkdone')
      .then((response) => {
        // commit('Auth/doneSelfchk', response.data, { root: true })
      })
      .catch((error) => {
        console.log(error)
      })
  },
  //
  // 自己評価リスト
  loadList ({ getters, commit }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/selfcheck/list')
        .then((response) => {
          // console.log(response.data)
          commit('list', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 自己評価リスト with id
  loadListById ({ getters, commit }, id) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/selfcheck/list/' + id)
        .then((response) => {
          // console.log(response.data)
          commit('list', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 自己評価詳細取得
  loadDetail ({ commit }, id) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/selfcheck/' + id)
        .then((response) => {
          commit('date', response.data.date)
          commit('range', response.data.range)
          commit('qanda', response.data.qanda)
          commit('hexagonalmap', response.data.qanda[2].answer)
          commit('meta', {
            no: response.data.no,
            name: response.data.name,
            belong: response.data.belong,
            jobtitle: response.data.jobtitle,
            joined: response.data.joined
          })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 自己評価保存
  saveSelfCheck ({ rootGetters, getters, commit }, staffid) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jjs/v2/selfcheck/add', {
          id: staffid,
          cat: getters.cat, // periodical, bonus
          range: getters.range,
          qanda: getters.qanda
        })
        .then((response) => {
          console.log(response.data)
          alert('自己評価を提出しました')
          router.push({ name: 'StaffDashboard' })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          // commit('Auth/doneSelfchk', true, { root: true })
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 自己評価リクエスト有効化
  activate ({ getters, commit, dispatch }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jjs/v2/selfcheck/activate/', getters.range)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
          dispatch('Client/loadClientData', null, { root: true })
          dispatch('Staff/loadStaffList', null, { root: true })
        })
    })
  },
  //
  // 自己評価リクエスト無効化
  inactivate ({ commit, dispatch }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/selfcheck/inactivate')
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
          dispatch('Client/loadClientData', null, { root: true })
          dispatch('Staff/loadStaffList', null, { root: true })
        })
    })
  },
  //
  // リセット
  reset ({ commit }) {
    commit('qanda', [
      {
        question: '1.今期、貢献できたこと、また改善できたこと、新たに取り組んだ仕事など',
        answer: ''
      },
      {
        question: '2.今期改善が必要な事項、結果が出せなかったこと等',
        answer: ''
      },
      {
        question: '3.対象期間において自分の仕事を下記の項目ごとに５段階で評価すると',
        answer: []
      },
      {
        question: '4.今後の課題、目標',
        answer: ''
      },
      {
        question: '5.その他仕事での気づき、意見',
        answer: ''
      }
    ])
    commit('hexagonalmap', [])
    commit('list', [])
    commit('date', '')
    commit('range', [])
    commit('meta', [])
  }
}

const SelfExam = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default SelfExam
