import axios from 'axios'

const state = {
  staffList: {},
  needEvalList: {}
}

const getters = {
  staffList: (state) => state.staffList,
  needEvalList: (state) => state.needEvalList
}

const mutations = {
  staffList (state, staffList) {
    state.staffList = staffList
  },
  needEvalList (state, needEvalList) {
    state.needEvalList = needEvalList
  }
}

const actions = {
  //
  // メンバー社員読み込み
  async loadMembers ({ getters, commit }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/member/list')
        .then((response) => {
          console.log(response.data)
          commit('staffList', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 評価が必要なメンバーリスト
  async loadNeedEvalList ({ commit }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/member/evallist')
        .then((response) => {
          commit('needEvalList', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  }
}

const Manager = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Manager
