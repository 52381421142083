import axios from 'axios'

const state = {
  mode: '',
  show: false,
  content: false,
  payload: {},
  error: false
}

const getters = {
  mode: (state) => state.mode,
  show: (state) => state.show,
  content: (state) => state.content,
  payload: (state) => state.payload,
  error: (state) => state.error
}

const mutations = {
  mode (state, mode) {
    state.mode = mode
  },
  show (state, show) {
    state.show = show
  },
  content (state, content) {
    state.content = content
  },
  payload (state, payload) {
    state.payload = payload
  },
  error (state, error) {
    state.error = error
  }
}

const actions = {
  //
  // 携帯電話番号の重複を確認
  //
  chkDuplicate ({ commit }, telno) {
    axios
      .post('/wp-json/jjs/v2/staff/chk-duplicate', telno)
      .then((response) => {
        commit('error', false)
      })
      .catch((error) => {
        if (error.response.status === 403) {
          commit('error', true)
        }
      })
  }
}

const Modal = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Modal
