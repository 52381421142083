<template>
  <div>
    <div class="sideblock" v-if="askeval && askeval.periodical > 0">
      <div class="selfchk-request">
        <h3>自己評価を提出してください</h3>
        <router-link :to="{ name: 'SelfEvaluation' }">自己評価(定期評価)</router-link>
      </div>
    </div>
    <div class="sideblock" v-if="askeval && askeval.bonus > 0">
      <div class="selfchk-request">
        <h3>自己評価を提出してください</h3>
        <router-link :to="{ name: 'SelfEvaluation' }">自己評価(賞与評価)</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      askeval: this.$store.getters.askeval
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.askeval,
      (newValue) => {
        console.log(newValue)
        this.askeval = newValue
      }
    )
  },
  methods: {
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style scoped>
.selfchk-request {
  background-color: #fff;
  padding: 15px;
  border: 1px solid var(--border-color);
}
.selfchk-request h3 {
  font-weight: bold;
  font-size: 0.8rem;
  margin-bottom: 0.5em;
}
.selfchk-request a {
  display: block;
  padding: 10px;
  background-color: var(--active-color);
  color: #000;
  font-weight: bold;
  text-align: center;
}
</style>
