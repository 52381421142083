import axios from 'axios'

const state = {
  classDefs: [],
  classSlogans: [],
  classSlogansSP: [],
  rankDefs: [],
  promoteDefs: [],
  promoteConds: [],
  raiseDefs: [],
  initialDefs: [],
  salaryDef: {},
  bonusDef: {},
  recruitDef: {},
  allowanceDef: {},
  evalDef: {},
  evalBonusDef: {}
}

const getters = {
  classDefs: (state) => state.classDefs,
  classSlogans: (state) => state.classSlogans,
  classSlogansSP: (state) => state.classSlogansSP,
  rankDefs: (state) => state.rankDefs,
  promoteDefs: (state) => state.promoteDefs,
  promoteConds: (state) => state.promoteConds,
  raiseDefs: (state) => state.raiseDefs,
  initialDefs: (state) => state.initialDefs,
  salaryDef: (state) => state.salaryDef,
  bonusDef: (state) => state.bonusDef,
  recruitDef: (state) => state.recruitDef,
  allowanceDef: (state) => state.allowanceDef,
  evalDef: (state) => state.evalDef,
  evalBonusDef: (state) => state.evalBonusDef
}

const mutations = {
  classDefs (state, classDefs) {
    state.classDefs = classDefs
  },
  classSlogans (state, classSlogans) {
    state.classSlogans = classSlogans
  },
  classSlogansSP (state, classSlogansSP) {
    state.classSlogansSP = classSlogansSP
  },
  rankDefs (state, rankDefs) {
    state.rankDefs = rankDefs
  },
  promoteDefs (state, promoteDefs) {
    state.promoteDefs = promoteDefs
  },
  promoteConds (state, promoteConds) {
    state.promoteConds = promoteConds
  },
  raiseDefs (state, raiseDefs) {
    state.raiseDefs = raiseDefs
  },
  initialDefs (state, initialDefs) {
    state.initialDefs = initialDefs
  },
  salaryDef (state, salaryDef) {
    state.salaryDef = salaryDef
  },
  bonusDef (state, bonusDef) {
    state.bonusDef = bonusDef
  },
  recruitDef (state, recruitDef) {
    state.recruitDef = recruitDef
  },
  allowanceDef (state, allowanceDef) {
    state.allowanceDef = allowanceDef
  },
  evalDef (state, evalDef) {
    state.evalDef = evalDef
  },
  evalBonusDef (state, evalBonusDef) {
    state.evalBonusDef = evalBonusDef
  }
}

const actions = {
  //
  // 昇格条件読込
  loadPromoteConds ({ commit }) {
    console.log('LOAD PROMOTE CONDS')
    axios
      .get('/wp-json/jjs/v2/def/promoteconds')
      .then((response) => {
        console.log(response.data)
        commit('promoteConds', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  //
  // 昇格条件保存
  savePromoteConds ({ getters }) {
    console.log('SAVE PROMOTE CONDS')
    axios
      .post('/wp-json/jjs/v2/def/promoteconds', getters.promoteConds)
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  //
  // 昇格定義読込
  async loadPromoteDefs ({ rootGetters, getters, commit }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/jjs/v2/def/promote')
        .then((response) => {
          // console.log(response.data)
          if (response.data) {
            commit('promoteDefs', response.data)
          } else {
            // 存在しない場合初期値からロード
            const promotedef = []
            rootGetters['Initial/rankDefs'].forEach(el => {
              promotedef.push({
                rank: el.rank,
                label: el.label,
                point: el.point
              })
            })
            commit('promoteDefs', promotedef)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 昇格定義保存
  async savePromoteDef ({ getters, commit }) {
    // console.log(getters.promoteDefs)
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/jjs/v2/def/promote', getters.promoteDefs)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 昇給定義読込
  async loadRaiseDefs ({ rootGetters, getters, commit }, no) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/jjs/v2/def/raise')
        .then((response) => {
          if (response.data) {
            commit('raiseDefs', response.data)
          } else {
            // 存在しない場合初期値からロード
            const result = []
            const raiseDef = rootGetters['Initial/rankDefs']
            const classDef = rootGetters['Initial/classDefs']
            const found = classDef.find(el => el.no === no)
            Object.keys(found.raise).forEach(el => {
              const raise = raiseDef.find(el2 => el2.rank === el)
              result.push({
                grade: el,
                arr: found.raise[el],
                score: raise.score
              })
            })
            console.log(result)
            commit('raiseDefs', result)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 昇給定義保存
  async saveRaiseDef ({ getters, commit }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/jjs/v2/def/raise', getters.raiseDefs)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // キャリア採用給与表読み込み
  //
  async loadRecruitDef ({ commit, rootGetters }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/jjs/v2/def/recruit')
        .then((response) => {
          // console.log(response.data)
          if (response.data) {
            commit('recruitDef', response.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // キャリア採用給与表保存
  //
  async saveRecruitDef ({ commit, getters, rootGetters }, data) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      console.log(data['高卒'])
      axios
        .post('/wp-json/jjs/v2/def/recruit/update', {
          高卒: data['高卒'],
          専門: data['専門'],
          短大: data['短大'],
          大卒: data['大卒'],
          院卒: data['院卒']
        })
        .then((response) => {
          console.log(response.data)
          const clientData = rootGetters['Client/clientData']
          clientData.settings.recruit = data
          commit('Client/clientData', clientData, { root: true })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 評価項目(賞与)読み込み
  //
  async loadEvalBonusDefs ({ commit, rootGetters }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/jjs/v2/def/evalbonus')
        .then((response) => {
          // console.log(response.data)
          if (response.data) {
            commit('evalBonusDef', response.data)
            const clientData = rootGetters['Client/clientData']
            clientData.settings.evalBonus = response.data
            commit('Client/clientData', clientData, { root: true })
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 評価項目(賞与)保存
  //
  async saveEvalBonusDef ({ commit, getters, rootGetters }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/jjs/v2/def/evalbonus/update', getters.evalBonusDef)
        .then((response) => {
          console.log(response.data)
          const clientData = rootGetters['Client/clientData']
          clientData.settings.evalBonus = getters.evalBonusDef
          commit('Client/clientData', clientData, { root: true })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 評価項目(年次)読み込み
  //
  async loadEvalDefs ({ commit, rootGetters }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/jjs/v2/def/eval')
        .then((response) => {
          // console.log(response.data)
          if (response.data) {
            commit('evalDef', response.data)
            const clientData = rootGetters['Client/clientData']
            clientData.settings.evaluations = response.data
            commit('Client/clientData', clientData, { root: true })
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 評価項目(年次)保存
  //
  async saveEvalDef ({ commit, getters, rootGetters }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/jjs/v2/def/eval/update', getters.evalDef)
        .then((response) => {
          console.log(response.data)
          const clientData = rootGetters['Client/clientData']
          clientData.settings.evaluations = getters.evalDef
          commit('Client/clientData', clientData, { root: true })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 手当保存
  //
  async saveAllowance ({ commit, getters, rootGetters }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/jjs/v2/def/allowance/update', getters.allowanceDef)
        .then((response) => {
          console.log(response.data)
          const clientData = rootGetters['Client/clientData']
          clientData.settings.allowance = getters.allowanceDef
          commit('Client/clientData', clientData, { root: true })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 初任給定義保存
  //
  async saveInitialDef ({ commit, getters }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/jjs/v2/def/initial/update', getters.initialDefs)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 初任給定義読み込み
  //
  async loadInitialDefs ({ commit }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/jjs/v2/def/initial')
        .then((response) => {
          // console.log(response.data)
          if (response.data) {
            commit('initialDefs', response.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 給与定義読み込み
  //
  async loadSalaryDefs ({ commit, getters }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/jjs/v2/def/salary')
        .then((response) => {
          // console.log(response.data)
          if (response.data) {
            commit('salaryDef', response.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 給与定義保存
  //
  async saveSalaryDef ({ commit, getters }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/jjs/v2/def/salary/update/', getters.salaryDef)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 賞与定義読み込み
  //
  async loadBonusDef ({ commit, getters }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/jjs/v2/def/bonus')
        .then((response) => {
          console.log(response.data)
          if (response.data) {
            commit('bonusDef', response.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 賞与定義保存
  //
  async saveBonusDef ({ commit, getters }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/jjs/v2/def/bonus/update/', getters.bonusDef)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 評価定義読み込み
  //
  async loadRankDefs ({ commit }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/jjs/v2/def/rank')
        .then((response) => {
          console.log(response.data)
          if (response.data) {
            commit('rankDefs', response.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 評価定義保存
  //
  async saveRankDef ({ rootGetters, getters, commit }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/jjs/v2/def/rank/update/', getters.rankDefs)
        .then((response) => {
          console.log(response.data)
          // const clientData = Object.assign({}, rootGetters['Client/clientInfo'])
          // clientData.settings.ranks = getters.rankDefs
          // commit('Client/clientData', clientData, { root: true })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // クラス定義読み込み
  //
  async loadClassDefs ({ commit }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/jjs/v2/def/class')
        .then((response) => {
          if (response.data) {
            commit('classDefs', response.data.classDefs)
            commit('classSlogans', response.data.classSlogans)
            commit('classSlogansSP', response.data.classSlogansSP)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // クラス定義保存
  //
  async saveClassDef ({ rootGetters, getters, commit }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/jjs/v2/def/class/update/', {
          classes: getters.classDefs,
          slogans: getters.classSlogans,
          slogansSP: getters.classSlogansSP
        })
        .then((response) => {
          console.log(response.data)
          // const clientData = Object.assign({}, rootGetters['Client/clientInfo'])
          // clientData.settings.classdefs = getters.classDefs
          // clientData.settings.classSlogan = getters.classSlogans
          // commit('Client/clientData', clientData, { root: true })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 全ての定義をリセット
  resetAllDefs ({ getters, commit }) {
    console.log('reset all defs')
    Object.keys(getters).forEach(el => {
      commit(el, [])
    })
  }
}

const Defs = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Defs
