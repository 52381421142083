<template>
  <div @click.stop>
    <h5>{{ title }}対象期間を設定してください</h5>
    <div class="evalrange">
      <div :class="{ error: !state.from }">
        <input type="date" v-model="from">
        <p>日付を指定してください</p>
      </div>
      <span>〜</span>
      <div :class="{ error: !state.to }">
        <input type="date" v-model="to">
        <p>日付を指定してください</p>
      </div>
    </div>
    <div class="sleeve">
      <button type="cancel" @click="$emit('close')">キャンセル</button>
      <button @click="placeRequest">OK</button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      title: '',
      from: '',
      to: '',
      state: {
        from: true,
        to: true
      }
    }
  },
  mounted () {
    if (this.$store.getters['Modal/mode'] === 'periodical') {
      this.title = '【定期評価】'
    }
    if (this.$store.getters['Modal/mode'] === 'bonus') {
      this.title = '【賞与評価】'
    }
  },
  methods: {
    placeRequest () {
      if (this.chkFilled()) {
        this.$store.commit('Eval/evalRange', [this.from, this.to])
        this.$store.dispatch('Eval/requestEval')
        this.$emit('close')
      }
    },
    chkFilled () {
      this.state.from = Boolean(this.from)
      this.state.to = Boolean(this.to)
      return this.state.from && this.state.to
    }
  },
  computed: {
  },
  watch: {
  }
}
</script>
