<template>
  <div id="nameplate">
    <div>
      <span v-if="username">{{ username }}</span>
      <span>{{ company }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      userinfo: {
        company: this.$store.getters.company,
        username: this.$store.getters.name
      }
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.company,
      (newValue) => {
        this.userinfo.company = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters.name,
      (newValue) => {
        this.userinfo.username = newValue
      }
    )
  },
  computed: {
    username () {
      return this.userinfo.username && this.userinfo.username !== null ? this.userinfo.username : false
    },
    company () {
      return this.userinfo.company && this.userinfo.company !== null ? this.userinfo.company : false
    }
  }
}
</script>

<style scoped>
#nameplate > div {
  font-weight: bold;
  border-bottom: 1px solid #aaa;
  min-width: 250px;
  text-align: right;
  font-weight: bold;
}
#nameplate span + span {
  font-size: 0.8rem;
  color: #666;
  margin-left: 1em;
}
</style>
