import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import Dashboard from '../views/DashboardView.vue'

const routes = [
  {
    // Dashboard
    path: '/',
    name: 'dashboard',
    meta: { title: 'ダッシュボード' },
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if (store.getters['Auth/userid'] === null) {
        next({ name: 'login' })
      }
      switch (store.getters['Auth/capability']) {
        case 'superadmin':
          next({ name: 'AdminDashboard' })
          break
        case 'owner':
          next({ name: 'Client', params: { dom: store.getters['Auth/domain'] } })
          break
        case 'manager':
          next({ name: 'ManagerDashboard' })
          break
        default:
          next({ name: 'StaffDashboard' })
      }
    }
  },
  {
    // Login
    path: '/login',
    name: 'login',
    meta: { title: 'ログイン' },
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    // Login（クライアント）
    path: '/:dom/login',
    name: 'ClientLogin',
    meta: { title: 'ログイン' },
    component: () => import(/* webpackChunkName: "ClientLogin" */ '../views/ClientLogin.vue')
  },
  {
    // 【社労士】ダッシュボード
    path: '/admin/dashboard',
    name: 'AdminDashboard',
    meta: { title: '社労士ダッシュボード' },
    component: () => import(/* webpackChunkName: "AdminDashboard" */ '../views/Admin/AdminDashboard.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/restoreMasterLogin')
      store.dispatch('Admin/loadClientList')
      next()
    }
  },
  {
    // 【社労士】クライアント追加
    path: '/admin/add-client',
    name: 'AddClient',
    meta: { title: 'クライアント新規追加' },
    component: () => import(/* webpackChunkName: "AddClient" */ '../views/Admin/AddClient.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/restoreMasterLogin')
      next()
    }
  },
  {
    // 【クライアント】
    path: '/client/:dom',
    beforeEnter: (to, from, next) => {
      // store.dispatch('Auth/branchLogin', to.params.dom)
      store.dispatch('Auth/clientLogin', to.params.dom)
      next()
    },
    children: [
      {
        // 【クライアント】詳細
        path: '',
        name: 'Client',
        meta: { title: '企業情報' },
        component: () => import(/* webpackChunkName: "Client" */ '../views/ClientView.vue')
      },
      {
        // 【クライアント】部門定義
        path: 'domain',
        name: 'DomainDef',
        meta: { title: '部門定義' },
        component: () => import(/* webpackChunkName: "DomainDef" */ '../views/Defs/Domain.vue')
      },
      {
        // 【クライアント】役割定義
        path: 'role',
        name: 'RoleDef',
        meta: { title: '役割定義' },
        component: () => import(/* webpackChunkName: "RoleDef" */ '../views/Defs/Role.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Defs/loadClassDefs')
          next()
        }
      },
      {
        // 【クライアント】評価・昇給
        path: 'grade',
        name: 'GradeDef',
        meta: { title: '評価・昇給' },
        component: () => import(/* webpackChunkName: "GradeDef" */ '../views/Defs/Grade.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Defs/loadPromoteDefs')
          // store.dispatch('Defs/loadRankDefs')
          store.dispatch('Defs/loadClassDefs')
          store.dispatch('Defs/loadPromoteConds')
          next()
        }
      },
      {
        // 【クライアント】初任給
        path: 'initial',
        name: 'InitialDef',
        meta: { title: '初任給' },
        component: () => import(/* webpackChunkName: "InitialDef" */ '../views/Defs/Initial.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Client/loadClientData')
          next()
        }
      },
      {
        // 【クライアント】給与テーブル
        path: 'salary',
        name: 'SalaryDef',
        meta: { title: '基本給・号数' },
        component: () => import(/* webpackChunkName: "SalaryDef" */ '../views/Defs/Salary.vue'),
        beforeEnter: (to, from, next) => {
          // store.dispatch('Defs/loadBonusDef')
          store.dispatch('Defs/loadClassDefs')
          // store.dispatch('Defs/loadRankDefs')
          store.dispatch('Defs/loadPromoteDefs')
          next()
        }
      },
      {
        // 【クライアント】キャリア採用
        path: 'career',
        name: 'CareerDef',
        meta: { title: 'キャリア採用' },
        component: () => import(/* webpackChunkName: "CareerDef" */ '../views/Defs/Career.vue'),
        beforeEnter: (to, from, next) => {
          // store.dispatch('Defs/loadRankDefs')
          store.dispatch('Defs/loadClassDefs')
          store.dispatch('Defs/loadInitialDefs')
          store.dispatch('Defs/loadSalaryDefs')
          store.dispatch('Defs/loadPromoteDefs')
          store.dispatch('Defs/loadRecruitDef')
          next()
        }
      },
      {
        // 【クライアント】手当
        path: 'allowance',
        name: 'AllowanceDef',
        meta: { title: '手当' },
        component: () => import(/* webpackChunkName: "AllowanceDef" */ '../views/Defs/Allowance.vue')
      },
      {
        // 【クライアント】賞与
        path: 'bonus',
        name: 'BonusDef',
        meta: { title: '賞与' },
        component: () => import(/* webpackChunkName: "BonusDef" */ '../views/Defs/Bonus.vue'),
        beforeEnter: (to, from, next) => {
          store.commit('Staff/staffList', {})
          store.dispatch('Defs/loadBonusDef')
          store.dispatch('Staff/loadStaffList')
          next()
        }
      },
      {
        // 【クライアント】評価項目（年次評価）
        path: 'eval',
        name: 'EvalDef',
        meta: { title: '評価項目（年次評価）' },
        component: () => import(/* webpackChunkName: "EvalDef" */ '../views/Defs/Eval.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Defs/loadClassDefs')
          store.dispatch('Defs/loadEvalDefs')
          next()
        }
      },
      {
        // 【クライアント】評価項目（賞与評価）
        path: 'eval-bonus',
        name: 'EvalBonusDef',
        meta: { title: '評価項目（賞与評価）' },
        component: () => import(/* webpackChunkName: "EvalBonusDef" */ '../views/Defs/EvalBonus.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Defs/loadClassDefs')
          store.dispatch('Defs/loadEvalBonusDefs')
          next()
        }
      },
      {
        // 【クライアント】賞与支給
        path: 'paybonus',
        name: 'PayBonus',
        meta: { title: '賞与支給' },
        component: () => import(/* webpackChunkName: "PayBonus" */ '../views/Owner/PayBonus.vue'),
        beforeEnter: (to, from, next) => {
          store.commit('Staff/staffList', [])
          store.dispatch('Staff/loadStaffListBonus')
          store.dispatch('Defs/loadBonusDef')
          next()
        }
      },
      {
        // 【管理職】ダッシュボード
        path: 'ManagerDashboard',
        name: 'ManagerDashboard',
        meta: { title: '管理職ダッシュボード' },
        component: () => import(/* webpackChunkName: "ManagerDashboard" */ '../views/Manager/DashboardView.vue'),
        beforeEnter: (to, from, next) => {
          if (store.getters['Auth/token']) {
            store.commit('Manager/staffList', {})
            // store.dispatch('Manager/loadMembers')
            // store.dispatch('Eval/getEvalPendingList')
            // store.dispatch('Manager/loadNeedEvalList')
          }
          next()
        }
      }
    ]
  },
  {
    // 【スタッフ】
    path: '/:dom/staff',
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/branchLogin', to.params.dom)
      next()
    },
    children: [
      {
        // 【スタッフ】一覧
        path: '',
        name: 'StaffList',
        meta: { title: '社員一覧' },
        component: () => import(/* webpackChunkName: "StaffList" */ '../views/Owner/StaffList.vue')
      },
      {
        // 【スタッフ】詳細
        path: ':id',
        children: [
          {
            // 【スタッフ】詳細
            path: '',
            name: 'StaffDetail',
            component: () => import(/* webpackChunkName: "StaffDetail" */ '../views/StaffDetail.vue'),
            meta: { title: '社員詳細データ' },
            beforeEnter: (to, from, next) => {
              store.commit('Staff/staffData', {})
              store.commit('mode', 'profile')
              store.dispatch('Staff/loadDetail', to.params.id)
              store.dispatch('SelfExam/loadListById', to.params.id)
              next()
            }
          },
          {
            // 【スタッフ】定期評価
            path: 'eval/teiki',
            name: 'EvalTeiki',
            meta: { title: '定期評価' },
            component: () => import(/* webpackChunkName: "EvalTeiki" */ '../views/Manager/EvalTeiki.vue'),
            beforeEnter: (to, from, next) => {
              store.dispatch('Staff/loadDetail', to.params.id)
              store.dispatch('Eval/loadExistingEval', to.params.id)
              next()
            }
          },
          {
            // 【スタッフ】賞与評価
            path: 'eval/bonus',
            name: 'EvalBonus',
            meta: { title: '賞与評価' },
            component: () => import(/* webpackChunkName: "EvalBonus" */ '../views/Manager/EvalBonus.vue'),
            beforeEnter: (to, from, next) => {
              store.dispatch('Staff/loadDetail', to.params.id)
              store.dispatch('Defs/loadEvalBonusDefs')
              store.dispatch('Eval/loadExistingEvalBonus', to.params.id)
              next()
            }
          }
        ]
      },
      {
        // 【スタッフ】評価グループ
        path: 'group',
        name: 'EvalGroup',
        meta: { title: '評価グループ' },
        component: () => import(/* webpackChunkName: "EvalGroup" */ '../views/Owner/EvalGroup.vue')
      }
    ]
  },
  {
    // 【スタッフ】追加
    path: '/:dom/staff/add',
    name: 'StaffAdd',
    meta: { title: '社員追加' },
    component: () => import(/* webpackChunkName: "StaffAdd" */ '../views/Owner/AddStaff.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/branchLogin', to.params.dom)
      next()
    }
  },
  {
    // 【人事評価】詳細
    path: '/:dom/eval',
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/branchLogin', to.params.dom)
      // store.dispatch('Defs/loadEvalDefs')
      next()
    },
    children: [
      {
        // 【人事評価】詳細
        path: ':id',
        name: 'EvalDetail',
        meta: { title: '評価詳細' },
        // component: () => import(/* webpackChunkName: "EvalDetail" */ '../views/EvalDetail.vue'),
        component: () => import(/* webpackChunkName: "EvalDetail" */ '../views/Manager/EvalTeiki.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Eval/getDetail', to.params.id)
          next()
        }
      }
    ]
  },
  // 【スタッフ】ダッシュボード
  {
    path: '/dashboard',
    name: 'StaffDashboard',
    meta: { title: 'スタッフダッシュボード' },
    component: () => import(/* webpackChunkName: "StaffDashboard" */ '../views/Staff/DashboardView.vue'),
    beforeEnter: (to, from, next) => {
      store.commit('Staff/staffData', {})
      store.dispatch('Auth/clientLogin', store.getters['Auth/domain'])
      store.dispatch('Staff/loadDetail', store.getters['Auth/userid'])
      store.dispatch('Eval/getPeriodicalList', store.getters['Auth/userid'])
      next()
    }
  },
  // 【スタッフ】自己評価
  {
    path: '/selfeval',
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/clientLogin', store.getters['Auth/domain'])
      store.dispatch('Staff/loadDetail', store.getters['Auth/userid'])
      next()
    },
    children: [
      {
        // 【スタッフ】自己評価記入
        path: '',
        name: 'SelfEvaluation',
        meta: { title: '自己評価記入' },
        component: () => import(/* webpackChunkName: "SelfEvaluation" */ '../views/Staff/SelfEvaluation.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('SelfExam/reset')
          // store.dispatch('Staff/loadDetail', store.getters['Auth/userid'])
          store.dispatch('Eval/getList', store.getters['Auth/userid'])
          next()
        }
      },
      {
        // 【スタッフ】自己評価履歴
        path: 'log',
        name: 'SelfEvalLog',
        meta: { title: '自己評価' },
        component: () => import(/* webpackChunkName: "SelfEvalLog" */ '../views/Staff/SelfEvalLog.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Eval/getList', store.getters['Auth/userid'])
          next()
        }
      },
      {
        // 【スタッフ】自己評価詳細
        path: ':id',
        name: 'SelfEvaluationDetail',
        meta: { title: '自己評価' },
        component: () => import(/* webpackChunkName: "SelfEvaluationDetail" */ '../views/Staff/SelfEvaluation.vue'),
        beforeEnter: (to, from, next) => {
          // store.dispatch('Staff/loadDetail', store.getters['Auth/userid'])
          store.dispatch('SelfExam/loadDetail', to.params.id)
          next()
        }
      }
    ]
  },
  // 【スタッフ】フィードバック
  {
    path: '/feedback',
    beforeEnter: (to, from, next) => {
      store.dispatch('Auth/branchLogin', store.getters['Auth/domain'])
      // store.dispatch('Staff/loadDetail', store.getters['Auth/userid'])
      next()
    },
    children: [
      {
        path: '',
        name: 'FeedbackView',
        meta: { title: 'フィードバック' },
        component: () => import(/* webpackChunkName: "FeedbackView" */ '../views/Staff/FeedbackView.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Eval/getPeriodicalList', store.getters['Auth/userid'])
          next()
        }
      },
      {
        path: ':id',
        name: 'FeedbackDetail',
        meta: { title: 'フィードバック' },
        component: () => import(/* webpackChunkName: "FeedbackDetail" */ '../views/Staff/FeedbackDetail.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('Eval/getDetail', to.params.id)
          next()
        }
      }
    ]
  },
  // 【スタッフ】評価履歴
  {
    path: '/eval-log',
    name: 'EvalLog',
    meta: { title: '評価履歴' },
    component: () => import(/* webpackChunkName: "EvalLog" */ '../views/Staff/EvalLog.vue')
  },
  // 【スタッフ】賞与履歴
  {
    path: '/bonus-log',
    name: 'BonusLog',
    meta: { title: '賞与履歴' },
    component: () => import(/* webpackChunkName: "BonusLog" */ '../views/Staff/BonusLog.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('currentTemplateName', to.name)
  next()
})

export default router
