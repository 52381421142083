<template>
  <li
    draggable="true"
    @dragstart.self="onDrag"
    @dragover.prevent
    @dragenter.prevent
  >
    <picture>
      <img :src="avatar" alt="name">
    </picture>
    <div>
      {{ name }}
      <div class="meta">{{ belong }} {{ job }}</div>
      <div class="control">
        <span class="boss" @click="unsetBoss" title="1次評価者"></span>
        <span class="bigboss" @click="unsetBigBoss" title="2次評価者"></span>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: ['name', 'id', 'avatar', 'belong', 'job'],
  methods: {
    onDrag (e) {
      e.dataTransfer.effectAllowed = 'move'
      e.dataTransfer.dropEffect = 'move'
      e.dataTransfer.setData('payload', this.$props.id)
    },
    unsetBoss () {
      this.$store.dispatch('Staff/unsetBoss', this.$props.id)
    },
    unsetBigBoss () {
      this.$store.dispatch('Staff/unsetBigBoss', this.$props.id)
    }
  }
}
</script>
