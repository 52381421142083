<template>
  <nav>
    <AdminMenu v-if="capability === 'superadmin'"></AdminMenu>
    <OwnerMenu v-else-if="capability === 'owner'"></OwnerMenu>
    <ManagerMenu v-else-if="capability === 'manager'"></ManagerMenu>
    <StaffMenu v-else></StaffMenu>
    <ul>
      <li @click="logout"><span>ログアウト</span></li>
    </ul>
    <AllStaffOps v-if="capability === 'manager' || capability === 'staff'"></AllStaffOps>
  </nav>
  <main>
    <header>
      <PageTitle></PageTitle>
      <CurrentUser></CurrentUser>
    </header>
    <router-view/>
  </main>
  <aside>
    <Utility></Utility>
  </aside>
  <ComWindow></ComWindow>
  <ModalWindow v-if="modal"></ModalWindow>
  <div
    id="errormsg"
    v-if="error"
    @click="resetError"
  >
    <div>{{ errorMsg }}</div>
  </div>
</template>

<script>
import AdminMenu from '@/components/LeftSideMenu/AdminMenu.vue'
import OwnerMenu from '@/components/LeftSideMenu/OwnerMenu.vue'
import ManagerMenu from '@/components/LeftSideMenu/ManagerMenu.vue'
import StaffMenu from '@/components/LeftSideMenu/StaffMenu.vue'
import Utility from '@/components/UtilityColumn.vue'
import CurrentUser from '@/components/CurrentUserPane.vue'
import PageTitle from '@/components/PageTitle.vue'
import ComWindow from '@/components/ComWindow.vue'
import ModalWindow from '@/components/ModalWindow.vue'
import AllStaffOps from '@/components/LeftSideMenu/AllStaffOps.vue'
export default {
  components: {
    AdminMenu,
    OwnerMenu,
    ManagerMenu,
    StaffMenu,
    Utility,
    CurrentUser,
    PageTitle,
    ComWindow,
    ModalWindow,
    AllStaffOps
  },
  data () {
    return {
      capability: this.$store.getters['Auth/capability'],
      error: false,
      errorMsg: '',
      modal: false
    }
  },
  mounted () {
    // 現在のユーザーの権限
    this.$store.watch(
      (state, getters) => getters['Auth/capability'],
      (newValue) => {
        this.capability = newValue
      }
    )
    // Processing表示
    this.$store.watch(
      (state, getters) => getters.processing,
      (newValue) => {
        this.processing = newValue
        const body = document.querySelector('body')
        if (newValue) {
          body.classList.add('processing')
        } else {
          body.classList.remove('processing')
        }
      }
    )
    // Error表示
    this.$store.watch(
      (state, getters) => getters.showError,
      (newValue) => {
        this.error = newValue
        this.errorMsg = this.$store.getters.errorMsg
      }
    )
    // Modal
    this.$store.watch(
      (state, getters) => getters['Modal/show'],
      (newValue) => {
        this.modal = newValue
      }
    )
  },
  methods: {
    logout () {
      this.$store.dispatch('Auth/logout')
    },
    setMeta (route) {
      if (route.meta.title) {
        const setTitle = route.meta.title + ' | COCOMIRA人事評価'
        document.title = setTitle
      }
    },
    resetError () {
      this.$store.commit('showError', false)
      this.$store.commit('errorMsg', '')
      this.$router.push('/')
    }
  },
  watch: {
    '$route' (route, from) {
      this.setMeta(route)
    }
  }
}
</script>
