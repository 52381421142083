<template>
  <div id="pagetitle">
    <h1 v-if="companyname">
      {{ companyname }}<i>{{ title }}</i>
    </h1>
    <h1 v-else>{{ title }}</h1>
  </div>
</template>

<script>
export default {
  data () {
    return {
      companyname: this.$store.getters['Auth/currentCompany'],
      title: this.$store.getters.title
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters['Auth/currentCompany'],
      (newValue) => {
        this.companyname = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters.title,
      (newValue) => {
        this.title = newValue
      }
    )
  }
}
</script>
