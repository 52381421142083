import axios from 'axios'

const state = {
  clientList: {}
}

const getters = {
  clientList: (state) => state.clientList
}

const mutations = {
  clientList (state, clientList) {
    state.clientList = clientList
  }
}

const actions = {
  // クライアントリスト読み込み
  async loadClientList ({ getters, commit }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/client/list')
        .then((response) => {
          console.log(response.data)
          commit('clientList', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  }
}

const Admin = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Admin
